(function(mainFunc) {

  	mainFunc(window.jQuery, window, document);

 }
	(function($, window, document) {

		//DOCUMENT READY
		$(function() {

			mobileMenu();

			//WINDOW RESIZE
			$(window).on('resize', function(){
				homePage();

				//EQUALIZE
				if( $('.page-about-us .bottom-content ul').length > 0 ) {
					$('.page-about-us .bottom-content ul li').equalize({
						windowStart: 540,
						coverage: 'min-width'
					});
				}

				//ADD MARGIN TO HEADER
				if( $('.mainpanel .header').length != 0 ) {
					var $headerHeight = $('.mainpanel .header').outerHeight();
					$('.inner-page .mainpanel .main-content').css({
						marginTop: $headerHeight
					});
				}

			});

			//WINDOW LOAD
			$(window).load(function(){

				var $toBeAppended = [];

				//LOAD AVAILABLE PORIFOLIOS
				$.ajax({
					url: '../includes/portfolio-list.php',
					dataType: 'json',
					data: {action: "all_portfolios"},
					type: 'post',
					success: function( $portfolio ) {

						//APPEND ALL ITEMS
						$portfolio.forEach(function($portfolioItem, index){
							$('.portfolio-list').append('<a rel="album-'+ $portfolioItem.categories +'" data-caption="'+ $portfolioItem.name +'" href="'+ $portfolioItem.img +'" class="portfolio-item fancybox all bg-img ' + $portfolioItem.categories + '" style="background-image:url(' + $portfolioItem.thumb + ')"><div class="bg-trans"><div class="portfolio-container"><h4>' + $portfolioItem.name + '</h4><p class="portfolio-type">' + $portfolioItem.type + '</p></div></div></a>');
						});

						//CHECK IF THE ITEMS EXCEEDS
						if( $('.portfolio-list .portfolio-item').length > 11 ){
							$('.portfolio-wrap').append('<button class="btn center-block load-more">Load More</button>');
						}

						$(document).off('multipleFilterMasonry');

						//INITIALIZE RANDOMIZE ITEMS THEN INITIALIZE MASONRY
						$('.portfolio-list').randomize('.portfolio-item').multipleFilterMasonry({
		                	itemSelector: '.portfolio-item',
							filtersGroupSelector: '.portfolio-nav',
		                });

		                //GET THE LEAST HEIGHT OF ITEMS											
						var $portfolioItemHeight = 0;
						$('.portfolio-list .portfolio-item').each(function(){
							if( $(this).height() > $portfolioItemHeight ) {
								$portfolioItemHeight = $(this).height();
							}
						});
						$('.portfolio-list').css({
							height: $portfolioItemHeight * 4
						});

						//HIDE OTHER ELEMENTS IF NOT SCOPED WITH WINDOW VIEW
						var $offsetTop = parseInt($(window).scrollTop()) + parseInt($(window).height());
						$('.portfolio-list .portfolio-item').each(function(){
							
							var $thisOffset = parseInt($(this).offset().top);
							if( $offsetTop > $thisOffset ) {
								$(this).css('opacity', '1');
							}
							else {
								$(this).css({
									marginTop: '100px',
									opacity: '0'
								});
							}
						});
					}
				});

				$(window).on('scroll', function(){		

					clearTimeout( $.data( this, "scrollCheck" ) );

					$.data( this, "scrollCheck", setTimeout(function() {
						$offsetTop = parseInt($(window).scrollTop()) + parseInt($(window).height());
		               	$('.portfolio-list .portfolio-item').each(function(){
							var $thisOffset = parseInt($(this).offset().top);
							if( $offsetTop > $thisOffset ) {
								$(this).css({
									marginTop: '0',
									opacity: '1'
								});
							}
							else {
								$(this).css({
									marginTop: '100px',
									opacity: '0'
								});
							}
						});
	    			}, 100) );
               	});

				$(window).resize();

				$(document).on('click', '.load-more', function(){					

					 //GET THE LEAST HEIGHT OF ITEMS											
					var $portfolioItemHeight = 0;
					$('.portfolio-list .portfolio-item').each(function(){
						if( $(this).height() > $portfolioItemHeight ) {
							$portfolioItemHeight = $(this).height();
						}
					});
					$('.portfolio-list').css({
						height: $portfolioItemHeight * [ Math.floor($('.portfolio-item').length / 3) + ($('.portfolio-item').length % 3)]
					});

					$(this).hide();

				});



				$('.portfolio-nav .button input[name="filter-portfolio"]').on('change', function(){

					var $portfolioFilter = $(this).val();

					$('.portfolio-nav .button').removeClass('active chosen');
				
	                $(this).closest('.button').addClass('active');

	                $('.portfolio-list .portfolio-item').each(function(){
	                	$(this).css({
	                		marginTop: '0',
	                		opacity: '1'
	                	})
	                });

	                
	                //CHECK IF THE PORTFOLIO ITEM EXCEEDS THE LIMIT OF 12 ITEMS
	                setTimeout(function(){
	                	if( $('.portfolio-list .portfolio-item').length > 12 ) {
		                	$('.load-more').fadeIn();

		                	var $portfolioItemHeight = 0;
							$('.portfolio-list .portfolio-item').each(function(){
								if( $(this).height() > $portfolioItemHeight ) {
									$portfolioItemHeight = $(this).height();
								}
							});
							$('.portfolio-list').css({
								height: $portfolioItemHeight * 4
							});

		                }
		                else {
		                	$('.load-more').hide();
		                }
	                }, 100);

	            });

			});

		});


		//FUNCTIONS
		function mobileMenu() {
		
			/* MOBILE MENU */
			$('.sidepanel .menu > .menu-item-has-children').append('<span class="glyphicon glyphicon-plus toggle-submenu"></span>');
			$('.sidepanel .menu > .menu-item-has-children').each(function(){
				var dataItem = $(this).attr('id');
				$(this).find('.sub-menu')
						.first()
						.attr('data-item', '#'+dataItem)
						.clone()
						.appendTo('.custom-submenu');
				$(this).find('.sub-menu').remove().end().find('.toggle-submenu').attr('data-target', '#'+dataItem);
			});
			
			$('.sidepanel .sub-menu > .menu-item-has-children').append('<span class="glyphicon glyphicon-plus toggle2-submenu"></span>');
			
			/* TOGGLE MENU CLICK */
			$('.toggle-submenu').on('click', function(e){
				var dataTarget = $(this).data('target');
				$('.sidepanel .menu')
					.hide()
					.closest('.sidepanel')
						.find('.sub-menu[data-item="'+dataTarget+'"]')
						.show()
					.end()
						.find('.bck-to-menu')
						.show();
			});
			
			/* 2ND LEVEL SUBMENU */
			$('.toggle2-submenu').click(function(e){
				var currentSubmenu = $(this).parent('li').find('.sub-menu');
				
				if( currentSubmenu.css('display', 'none') ) {
					currentSubmenu.slideDown('fast');
					$(this).removeClass('glyphicon-plus').addClass('glyphicon-minus');
				}
				else {
					currentSubmenu.slideUp('fast');
					$(this).removeClass('glyphicon-minus').addClass('glyphicon-plus');
				}
				e.stopPropagation();
			});
			
			/* BACK TO MENU CLICK */
			$('.bck-to-menu').click(function() {
				$(this).hide();
				$('.sidepanel .menu').show();
				$('.custom-submenu .sub-menu').hide();
			});

			//MOBILE MENU
			$('.mobile-menu-btn, .menu-label').click(function(){
				$('.mainpanel').stop().animate({
						marginRight: '-300px',
						paddingLeft: '300px'
					},'fast');
				$('.sidepanel').stop().animate({
						left: 0
					},'fast');
					
				$(this).css('opacity', 0);
			});
			$('.toggle-mobile-menu').click(function(){
				$('.mainpanel').stop().animate({
						marginRight: '0px',
						paddingLeft: '0px'
					},'fast');
				$('.sidepanel').stop().animate({
						left: '-300px'
				},'fast');
				$('.mobile-menu-btn, .menu-label').css('opacity', "");
			});
			
			
		}

		function homePage() {
			var $windowHeight = $(window).height(),
				$mainContent = $('.home-page .main-content');
				$mainContentHeight = $mainContent.outerHeight();

			$('.maincontent-wrapper').removeAttr('style');

			//CHECK IF MAIN CONTENT  IS TALLER THAN DOCUMENT
			if( $windowHeight > $mainContentHeight ) {
				$('.maincontent-wrapper').height(parseInt($windowHeight) - 330);
			}
		}

		$.fn.randomize = function(childElem) {
		  	return this.each(function() {
	      	var $this = $(this);
		    var elems = $this.children(childElem);

		      elems.sort(function() { return (Math.round(Math.random())-0.5); });  

		      $this.remove(childElem);  

		      for(var i=0; i < elems.length; i++)
		        $this.append(elems[i]);      

		  });    
		}

		function portfolioScrollEffect() {

		}

	})
);
