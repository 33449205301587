( function( $ ) {


	//EQUALIZE METHOD
	$.fn.equalize = function( options ) {

		if ( $(this).length ) {

			var $settings = $.extend({
				windowStart: 768,
				coverage: 'min-width'
			}, options);

			if( Modernizr.mq('(' + $settings.coverage + ':' + $settings.windowStart + 'px)') ) {
				var $maxHeight = 0;
				$(this).each(function(){
				   if ($(this).height() > $maxHeight) {
				   		$maxHeight = $(this).height(); 
				   	}
				});
		
				$(this).css({ 
					height: $maxHeight
				});	
			}
			else {
				$(this).css({
					height: 'auto'
				});
			}

		}	

	};


	$.fn.accordion = function( options ) {

		if( $(this).length ) {

			var $settings = $.extend({
					toggleType: 'single',
					content: '',				
				}, options);

			$(this).attr('id', 'accordion-'+$(this).attr('class'));

			var $child = document.getElementById('accordion-'+$(this).attr('class')).firstElementChild.nodeName,
				$content = $settings.content;

			//CHECK IF THERE'S CONTENT SET
			if( $settings.content != '' ) {
				$($child+':not(:first-child)').find( $settings.content ).hide();
			}
			else {
				$($child+':not(:first-child)').children().hide();
			}

			//ACCORDION CLICK	
			$(this).find($child).click(function(e){

				//CHECK FOR TOGGLE TYPE
				if( $settings.toggleType == "single" ) {

					//CHECK IF THERES CONTENT SET
					if ($settings.content != '') {

						$($child).find($settings.content).slideUp();

						if( $(this).find($settings.content).css('display') == 'none' ) {

							$(this).find($settings.content).slideDown();

						}
						else {
							e.preventDefault();
						}

					}
					else {

						$($child).children().slideUp();

						if( $(this).children().css('display') == 'none' ) {

							$(this).children().slideDown();

						}
						else {
							e.preventDefault();
						}

					}

				}
				else if ( $settings.toggleType == "multiple" ) {

					//CHECK IF THERES CONTENT SET
					if ($settings.content != '') {
						$(this).find($settings.content).slideToggle(400);
					}
					else {
						$(this).children().slideToggle(400);
					}

				}


			});

		}

	};


}( jQuery ));